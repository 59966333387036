import React from "react"
import gabriela from "@components/pageNosotros/imgNosotros/img-inspired/gabriela.png"
import elio from "@components/pageNosotros/imgNosotros/img-inspired/elio.png"
import liliana from "@components/pageNosotros/imgNosotros/img-inspired/liliana.png"

const Inspired = () => {
  return (
    <div className="section-inspired">
      <p className="title-inspired">Historias que nos inspiran 💛</p>
      <div className="section-inspired-container">
        <p className="section-inspired-container-text">
          En Beex no solo nos enfocamos en ayudar a los negocios, sino
          también le extendemos nuestra mano a todo nuestro equipo para cumpla
          con sus metas personales.
          <br />
          Conoce las historias de superación de parte de nuestro equipo.{" "}
        </p>
        <div className="section-inspired-container-imagenes">
          <img src={gabriela} alt="" className="section-inspired-container-imagenes-One"/>
          <img src={elio} alt="" className="section-inspired-container-imagenes-Two"/>
          <img src={liliana} alt="" className="section-inspired-container-imagenes-Three"/>
        </div>
      </div>
    </div>
  )
}

export default Inspired
