/* eslint-disable no-undef */
import React from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Medios from "@components/sharedComponents/medios/medios"
import dataOne from "@components/pageHome/data"

import data from "@components/pageNosotros/data"
import StartNosotros from "@components/pageNosotros/components/startNosotros"
import AboutUs from "@components/pageNosotros/components/aboutUs"
import Galery from "@components/pageNosotros/components/galery"
import Teams from "@components/pageNosotros/components/teams"
import Message from "@components/pageNosotros/components/message"
import ADN from "@components/pageNosotros/components/ADN"
import History from "@components/pageNosotros/components/history"
import Inspired from "@components/pageNosotros/components/inspired"


const StructurePageNosotros = ({ location }) => {
  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true
  return (
    <div className="fnd">
    <section>
      <BannerCookies />
      <Header
        path="/nosotros/"
        location={location}
        windowsWidth={windowsWidth}
      />
      <div className="container">
        <StartNosotros data={data.start} location={location} />
        <AboutUs data={data.aboutus} location={location} />
        <Message />
        <ADN data={data.adn} />
        <Teams data={data.teams} location={location} />
        <Galery data={data.gallery} location={location} />
        <History data={data.history} />
        <Inspired />
        <Medios data={dataOne.medios} />
        <BannerContact data={data.contacts} location={location} />
        <section className="background-pagesnosotros" />
        <Footer data={dataOne.footer} location={location} />
        <FooterMobile data={dataOne.footer} location={location} />
      </div>
    </section>
    </div>
  )
}

export default StructurePageNosotros
